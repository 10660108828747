import axios from "axios";

export default {
  getFachlicheAnforderungen(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD + "/fachlicheAnforderungen/" +
      systemid.substring(1)
    );
  },
  updateFachlicheAnforderungen(fachlicheAnforderungen, systemid) {
    return axios.post(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/fachlicheAnforderungen/" +
        systemid.substring(1),
      fachlicheAnforderungen
    );
  },
  deleteFachlicheAnforderungen(fachlicheAnforderungenID) {
    return axios.delete(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/fachlicheAnforderungen/" +        
        fachlicheAnforderungenID.substring(1)
    );
  }
};
