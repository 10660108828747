<template>
  <div>
    <v-toolbar flat>
      <help />
      <v-toolbar-title>Fachliche Anforderungen</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="add" class="primary">hinzufügen</v-btn>
    </v-toolbar>
    <v-form ref="anforderungen_form" v-model="valid">
      <v-container>
        <div>
          <div
            v-for="anf in anforderungen"
            :key="anf.id"
            class="text-fields-row"
          >
            <v-row>
              <v-col cols="12" sm="6" md="11">
                <v-textarea
                  label="Anforderung"
                  v-model="anf.beschreibung"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  :rules="rules.anforderung"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-btn
                  @click="remove(anf.id)"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon dark v-bind="attrs" v-on="on">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> löschen</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              class="mr-1"
              outlined
              @click="saveFachlicheAnforderungen"
              v-if="anforderungen.length > 0 || form_saved == false"
            >
              speichern
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              outlined
              @click="refreshFachlicheAnforderungen"
              v-if="anforderungen.length > 0 || form_saved == false"
            >
              Zurücksetzen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SystemService from "@/services/old/SystemService.js";
import FachlicheAnforderungenService from "@/services/old/FunktionaleAnforderungenService.js";
import Help from "@/components/old/help/FachlicheAnforderungHelp.vue";
export default {
  name: "AnforderungenFachlich",
  components: { Help },
  data() {
    return {
      valid: true,
      rules: {
        anforderung: [v => !!v || "Beschreibung erforderlich"]
      },
      anforderungen: [],
      snackbar: false,
      message: "",
      snackbar_timeout: 2000,
      form_saved: true
    };
  },

  methods: {
    refreshFachlicheAnforderungen() {
      FachlicheAnforderungenService.getFachlicheAnforderungen(
        this.$store.getters.currentSystemId
      ).then(response => {
        this.anforderungen = response.data;
      });
    },
    add() {
      this.anforderungen.push({
        beschreibung: ""
      });
      this.form_saved = false;
    },

    async remove(index) {
      this.anforderungen.splice(index, 1);

      this.form_saved = false;
    },
    async saveFachlicheAnforderungen() {
      this.validate();
      if (this.valid) {
        let resp = await FachlicheAnforderungenService.updateFachlicheAnforderungen(
          this.anforderungen,
          this.$store.getters.currentSystemId
        );

        if (resp) {
          this.anforderungen = resp.data;
          if (resp.status == 200) {
            this.message = "Änderungen wurden gespeichert.";
            this.form_saved = true;
          } else {
            this.message = resp.data;
          }
        }
        this.refreshCurrentSystem();
        this.snackbar = true;
      }
    },
    async refreshCurrentSystem() {
      let resp1 = await SystemService.getNewestVersionOfSystem(
        this.$store.getters.currentSystemId
      );
      var currSystem = resp1.data;
      this.$store.dispatch("registerSystem", currSystem);

      if (resp1.status == 200) {
        this.message = "Änderungen wurden gespeichert.";
        this.form_saved = true;
      } else {
        this.message = resp1.errorMessage;
      }
    },

    validate() {
      this.$refs.anforderungen_form.validate();
    }
  },
  created() {
    this.refreshFachlicheAnforderungen();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
